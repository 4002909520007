import { InjectionToken } from '@angular/core';
import { ICurrency, ILanguage } from '@bs24/core/models/currency';
import { ExternalScript } from './externalScript';

export class EnvConfig {
  project?: string;
  production!: boolean;
  api!: {
    endpoint?: string
    ws?: string;
  };
  appVersion!: string;
  bookmakerId!: number;
  webAppId!: number;
  externalScripts?: Array<ExternalScript>;
  features?: {
    pages?: {
      limitations: boolean;
    };
    registration: 'email' | 'phone',
    ogImage?: string;
    validators?: any;
    documentNeeded?: boolean;
    showNoBalanceDialog?: boolean;
    responsibleGaming?: {
      realityChecker: boolean
    };
    maxFileSize?: number
  };
  keys?: {
    googleApiKey?: string,
    oddInBrandToken?: string;
    altenar?: string;
  };
  languages!: Array<ILanguage>;
  currency!: ICurrency;
  theme: any;
  timeZone!: string;
  piqMerchantId?: string;
  trackers?: Array<{ name: string, url: string }>;
}

export const Language = new InjectionToken<string>('Language');
